/**@jsx jsx */
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { motion, AnimatePresence } from "framer-motion"
import { LoadFont } from "boostly-ui2"
import Form from "../components/Form"
import Confirm from "../components/Confirm"

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`
const MotionWrapper = ({ children, ...props }) => {
  return (
    <motion.div
      css={css`
        position: absolute;
        height: 100%;
        width: 100%;
      `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, zIndex: -2 }}
      transition={{ opacity: { duration: 0.25 } }}
      {...props}
    >
      {children}
    </motion.div>
  )
}
const Index = () => {
  const [index, setIndex] = useState(0)
  const handleDone = () => {
    setIndex(prev => (prev === 0 ? 1 : 0))
  }
  const views = [
    <MotionWrapper key={0}>
      <Form onDone={handleDone} />,
    </MotionWrapper>,
    <MotionWrapper key={1}>
      <Confirm onDone={handleDone} />
    </MotionWrapper>,
  ]
  return (
    <Container>
      <LoadFont />
      <AnimatePresence>{views[index]}</AnimatePresence>
    </Container>
  )
}

export default Index
