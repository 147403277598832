import React from "react"
import styled from "@emotion/styled"

const ButtonPrimary = ({
  mt,
  w = "auto",
  minW = "none",
  h = "auto",
  onClick,
  color = `hsl(356, 78%, 62%)`,
  className,
  enabled,
  disabled,
  ...props
}) => {
  const Button = styled.button`
    position: relative;
    height: ${h};
    width: ${w};
    margin-top: ${mt};
    min-width: ${minW};
    background-color: ${color};
    border: none;
    padding: 3px 0px;
    border-radius: 999px;
    color: white;
    font-size: 36px;
    font-family: righteous;
    letter-spacing: 2px;
    box-sizing: border-box;
    text-align: center;
    border: 3px solid hsl(0, 0%, 90%);
    filter: ${disabled && `brightness(70%)`};
    &:focus {
      outline: 0;
    }
  `
  return (
    <Button
      onClick={onClick}
      className={className}
      disabled={disabled}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default ButtonPrimary
