/**@jsx jsx */
import { useState } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
const Button = styled.button`
  border: none;
  background-color: transparent;
  &:focus {
    outline: 0;
  }
`
const touchStyle = css`
  /* &:hover { */
  filter: brightness(150%);
  /* } */
`
const DeleteButton = ({ css, onTouch, ...props }) => {
  const [isTouch, setTouch] = useState(false)
  const handleTouchStart = () => {
    setTouch(true)
    onTouch()
  }
  return (
    <Button
      css={isTouch && touchStyle}
      {...props}
      title="backspace"
      onTouchStart={handleTouchStart}
      onTouchEnd={() => setTouch(false)}
    >
      <svg
        width="77px"
        height="52px"
        viewBox="0 0 77 52"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g id="Del-Number-Copy" transform="translate(0.000000, 2.000000)">
            <path
              d="M4,-4.61852778e-14 L49.5335758,-4.61852778e-14 C50.6002499,-4.37166874e-14 51.6227416,0.426041422 52.3738276,1.18344717 L72.1872697,21.1636029 C73.7413153,22.7307245 73.732435,25.2604074 72.1674254,26.8165798 L52.3704387,46.5017778 C51.6209548,47.2470296 50.6069732,47.6653537 49.5500312,47.6653537 L4,47.6653537 C1.790861,47.6653537 4.19476067e-15,45.8744927 0,43.6653537 L0,4 C-2.705415e-16,1.790861 1.790861,-4.57794656e-14 4,-4.61852778e-14 Z"
              id="Rectangle"
              stroke="#FDE4FD"
              strokeWidth={3}
              fill="#8147FF"
              transform="translate(37.500000, 23.832677) scale(-1, 1) translate(-37.500000, -23.832677) "
            />
            <path
              d="M33,10.5 L58,37.5"
              id="Line"
              stroke="#FFFFFF"
              strokeWidth={5}
              strokeLinecap="round"
            />
            <path
              d="M59,11.5 L32,36.5"
              id="Line-Copy"
              stroke="#FFFFFF"
              strokeWidth={5}
              strokeLinecap="round"
            />
          </g>
        </g>
      </svg>
    </Button>
  )
}

export default DeleteButton
