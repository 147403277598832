/**@jsx jsx */
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { TitleSecondary } from "./Titles"
import { useInterval } from "../utils/customHooks"

const Background = styled.div`
  background-image: url(${require("../images/timer.svg")});
  background-size: cover;
  background-position: center top;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Timer = ({ onTimeout = _ => _, duration = 30, ...props }) => {
  const [secondsRemaining, setSeconds] = useState(duration)
  const [delay, setDelay] = useState(1000)
  useInterval(() => {
    setSeconds(prev => prev - 1)
  }, delay)
  useEffect(() => {
    if (secondsRemaining === 0) {
      setDelay(false)
      onTimeout()
    }
  }, [secondsRemaining, onTimeout])

  useEffect(() => {
    if (duration === null) {
      setDelay(null)
    } else {
      setDelay(1000)
      setSeconds(duration)
    }
  }, [duration])
  return (
    <Background {...props} >
      <TitleSecondary
        css={css`
          color: white;
          margin-right: 3px;
          margin-top: 2px;
        `}
      >
        {secondsRemaining}
      </TitleSecondary>
    </Background>
  )
}

export default Timer
