/**@jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { TitlePrimary, TitleTertiary } from "./Titles"
const Box = styled.div`
  position: relative;
  background: hsla(259, 67%, 37%, 40%);
  border: 3px solid hsl(300, 95%, 92%);
  max-height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
`
const InputText = styled(TitlePrimary)`
  color: hsla(0, 0%, 100%, 80%);
  text-align: center;
  letter-spacing: 1px;
  font-size: 200%;
`
const FocusStyle = css`
  color: hsla(0, 0%, 100%, 100%);
`
const InvalidInputStyle = css`
  border: 3px solid lightgreen;
`
const ErrorTitle = styled(TitleTertiary)`
  position: absolute;
  bottom: -30px;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 18px;
`
const InputBox = ({ text, className, mode = "PHONE", valid, ...props }) => {
  const formatter = text => {
    if (mode === "PHONE" && text !== undefined) {
      var x = text.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    } else if (mode === "BDAY" && text !== undefined) {
      var x = text.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})/)
      return !x[2] ? x[1] : x[1] + " - " + x[2]
    }
  }
  return (
    <>
      <TitleTertiary
        css={css`
          align-self: center;
          color: white;
        `}
      >
        Enter Your {mode === "PHONE" ? `Phone Number` : `Birthday`}
      </TitleTertiary>
      <Box {...props} css={valid && InvalidInputStyle}>
        <InputText css={text && text.length > 0 && FocusStyle}>
          {formatter(text) || (mode === "PHONE" ? "(XXX) XXX-XXXX" : "MM - DD")}
        </InputText>
        {mode === "BDAY" && !valid && text.length > 2 && (
          <ErrorTitle>Input needs to be MM - DD</ErrorTitle>
        )}
      </Box>
    </>
  )
}
export default InputBox
