/**@jsx jsx*/
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

export const setup = css`
  margin: 0;
  padding: 0;
`

export const TitlePrimary = ({
  fontFamily = "inherit",
  children,
  ...props
}) => {
  const Primary = styled.h1`
    ${setup};
    font-size: 32px;
    font-weight: 300;
    font-family: inherit;
    text-align: left;
    font-family: ${fontFamily};
    @media (max-width: 500px) {
      font-size: 28px;
    }
  `
  return (
    <Primary as="h1" {...props}>
      {children}
    </Primary>
  )
}

export const TitleSecondary = ({ children, ...props }) => {
  const Secondary = styled.h2`
    ${setup};
    font-size: 30px;
    font-weight: 300;
    font-family: inherit;
    @media (max-width: 500px) {
      font-size: 24px;
    }
  `
  return (
    <Secondary as="h2" {...props}>
      {children}
    </Secondary>
  )
}

export const TitleTertiary = ({ children, ...props }) => {
  const Secondary = styled.h3`
    ${setup};
    font-size: 20px;
    font-weight: 300;
    font-family: inherit;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  `
  return (
    <Secondary as="h2" {...props}>
      {children}
    </Secondary>
  )
}

export const Caption = ({ children, ...props }) => {
  const Description = styled.h4`
    ${setup};
    font-size: 16px;
    font-weight: 300;
    font-family: inherit;
  `
  return (
    <Description as="h3" {...props}>
      {children}
    </Description>
  )
}
