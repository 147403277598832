/**@jsx jsx */
import React, { useState, useMemo } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { useInterval } from "../utils/customHooks"
import { motion, AnimatePresence } from "framer-motion"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const FoodShots = styled(motion.img)`
  width: 100%;
  height: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-size: 120% 100%;
  mask-repeat: no-repeat;
  position: absolute;
`

const variants = {
  enter: () => {
    return {
      x: 1000,
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      opacity: 0,
    }
  },
}

const Slideshow = ({ imageURLs, className, ...props }) => {
  const [index, setIndex] = useState(0)

  useInterval(() => {
    setIndex(prev => (prev + 1) % imageURLs.length)
  }, 10000)

  return (
    <Container className={className}>
      <AnimatePresence>
        <FoodShots
          key={index}
          variants={variants}
          src={imageURLs[index]}
          alt="Picture of Food"
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", mass: 100, stiffness: 200, damping: 600 },
            opacity: { duration: 3 },
          }}
        />
      </AnimatePresence>
    </Container>
  )
}

export default Slideshow
