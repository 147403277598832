/**@jsx jsx */
import React, { useState } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { TitlePrimary } from "./Titles"
import DeleteButton from "./DeleteButton"

const Button = styled.button`
  font-size: 50px;
  font-family: inherit;
  border-radius: 100%;
  border: 3px solid hsl(300, 95%, 92%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 72px;
  color: white;
  background: hsl(259, 100%, 64%);
  &:focus {
    outline: 0;
  }
`
const touchStyle = css`
  /* &:hover { */
  filter: brightness(150%);
  /* } */
`

const NumericButton = ({ children, onTouch, ...props }) => {
  const [isTouch, setTouch] = useState(false)
  const handleTouchStart = () => {
    setTouch(true)
    onTouch()
  }
  return (
    <Button
      css={isTouch && touchStyle}
      {...props}
      onTouchStart={handleTouchStart}
      onTouchEnd={() => setTouch(false)}
    >
      {children}
    </Button>
  )
}

export default NumericButton
