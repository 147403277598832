/**@jsx jsx */
import React, { useState, useEffect } from "react"
import { useDebounce } from "../utils/customHooks"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import BoostlyWaterMark from "../images/poweredByBoostly.png"
import { LoadFont, Col } from "boostly-ui2"
import { TitlePrimary, TitleTertiary, Caption } from "./Titles"
import ButtonPrimary from "./Buttons"
import InputBox from "./InputBox"
import Numpad from "./Numpad"
import Timer from "./Timer"
import Slideshow from "./Slideshow"
import api from "../api"
import Spinner from "react-spinkit"
import queryString from "query-string"

const Layout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100vh;
  max-height: 100vh;
  font-family: righteous;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow: hidden;
`
const UserInput = styled.section`
  mask: url(${require("../images/WaveMask.svg")});
  mask-size: cover;
  position: absolute;
  background-color: hsla(0, 0%, 0%, 75%);
  background-image: url(${require(`../images/wavesAndBubbles.png`)});
  background-size: cover;
  /* padding-top: 24px;
  padding-bottom: 0px; */
  padding-left: 40px;
  right: 0px;
  z-index: 2;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PoweredByBoostly = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 99;
`
const Countdown = styled(Timer)`
  position: absolute;
  z-index: 99;
  right: 12px;
  top: 12px;
`
const LoadAnimation = styled(Spinner)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
`
const signupStep = {
  PHONE: "PHONE",
  BDAY: "BDAY",
}

const PromoTitle = ({ promo }) => (
  <>
    <TitlePrimary>Join and get</TitlePrimary>
    <TitlePrimary
      data-testid="deal"
      css={css`
        color: white;
      `}
    >
      {promo || "Loading promotion..."}
    </TitlePrimary>
  </>
)

const BdayTitle = () => (
  <>
    <TitlePrimary>
      Want
      <span
        css={css`
          color: white;
        `}
      >
        &nbsp;Free Stuff&nbsp;
      </span>
      on your birthday?
    </TitlePrimary>
  </>
)

const parseQueryParams = window => queryString.parse(window.location.search)

const Form = ({ onDone }) => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [birthday, setBirthday] = useState("")
  const [inputIsValid, setInputValid] = useState({
    phoneNumber: false,
    birthday: false,
  })
  const [duration, setDuration] = useState(20)
  const [step, setStep] = useState(signupStep.PHONE)
  const debouncedBirthday = useDebounce(birthday, 5000)
  const [kioskData, setKioskData] = useState({
    subCampaignId: "",
    incentive: "",
    imageURLs: null,
  })
  const [isSubscribing, setIsSubscribing] = useState(false)

  const handleInput = input => {
    if (step === signupStep.PHONE) {
      if (input === "x") {
        setPhoneNumber(prev => prev.slice(0, -1))
      } else if (phoneNumber.length < 10) {
        setPhoneNumber(prev => prev + input)
      }
    } else if (step === signupStep.BDAY) {
      if (input === "x") {
        setBirthday(prev => (prev === "" ? "" : prev.slice(0, -1)))
      } else if (birthday.length === 0 && parseInt(input) > 1) {
        setBirthday("0" + input)
      } else if (birthday.length === 2 && parseInt(input) > 3) {
        setBirthday(prev => prev + "0" + input)
      } else if (birthday.length < 4) {
        setBirthday(prev => prev + input)
      }
    }
  }

  const loadHandler = res => {
    const { config, promo } = res.data
    const promoImageURLs = config.promoImageURLs.map(image => image.url)
    setKioskData({
      subCampaignId: config.subCampaignId,
      incentive: promo.name,
      imageURLs: promoImageURLs,
    })
  }

  useEffect(() => {
    const source = api.getCancelToken()
    const loadData = () => {
      const params = parseQueryParams(window)
      api
        .load({ kioskId: params.kioskId })
        .then(loadHandler)
        .catch(error => {
          console.log(error)
        })
    }

    loadData()
    return () => {
      source.cancel()
    }
  }, [])

  //TODO: fix these names later
  const subscribe = () => {
    const bdayMonth = birthday.substring(0, 2)
    const bdayDay = birthday.substring(2, 4)
    setIsSubscribing(true)
    api
      .subscribe({
        campaignId: kioskData.subCampaignId,
        potential: {
          phone: phoneNumber,
          birthdayDay: bdayDay,
          birthdayMonth: bdayMonth,
        },
      })
      .then(_ => {
        onDone()
        setIsSubscribing(false)
      })
      .catch(_ => {
        //TODO: For now we want to pretend to succeed, UI-wise.
        onDone()
        setIsSubscribing(false)
      })
  }

  useEffect(() => {
    setInputValid(prev => {
      return { ...prev, phoneNumber: phoneNumber.length === 10 }
    })
  }, [phoneNumber])

  useEffect(() => {
    if (debouncedBirthday) {
      setDuration(20)
    }
  }, [debouncedBirthday])

  useEffect(() => {
    step === signupStep.BDAY && setDuration(null)
    let valid = false
    if (birthday.length === 4) {
      valid = /(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])/.test(birthday)
    }
    setInputValid(prev => {
      return { ...prev, birthday: valid }
    })
  }, [birthday])

  const handleClick = e => {
    if (step === signupStep.PHONE) {
      setStep(signupStep.BDAY)
      setInputValid(false)
    } else if (step === signupStep.BDAY) {
      subscribe()
    }
  }

  const handleTimeout = () => {
    setDuration(0)
    subscribe()
  }
  return (
    <>
      <Layout>
        <PoweredByBoostly src={BoostlyWaterMark} />
        {kioskData.imageURLs && (
          <Slideshow
            imageURLs={kioskData.imageURLs}
            css={css`
              position: relative;
              grid-column-start: 1;
              background: darkgray;
            `}
          />
        )}
        <UserInput>
          {step === signupStep.BDAY && duration && (
            <Countdown
              onTimeout={handleTimeout}
              duration={10}
              data-testid="timer"
            />
          )}
          <Col
            width="60%"
            space="evenly"
            height="100%"
            css={css`
              padding: 20px 0px;
            `}
          >
            <Col width="100%">
              {step === signupStep.PHONE ? (
                <PromoTitle promo={kioskData.incentive} />
              ) : (
                <BdayTitle />
              )}
            </Col>
            <InputBox
              text={step === signupStep.PHONE ? phoneNumber : birthday}
              mode={step}
              valid={
                step === signupStep.PHONE
                  ? inputIsValid.phoneNumber
                  : inputIsValid.birthday
              }
              data-testid="inputBox"
            />
            <Numpad
              css={css`
                margin: 20px 0px;
              `}
              onInput={handleInput}
              data-testid="numpad"
            />
            <ButtonPrimary
              h="65px;"
              disabled={
                step === signupStep.PHONE
                  ? !!!inputIsValid.phoneNumber
                  : !!!inputIsValid.birthday || isSubscribing
              }
              data-testid="mainButton"
              onTouchEnd={handleClick}
              // onMouseUp={handleClick}
              color={
                step === signupStep.PHONE
                  ? `hsl(356, 78%, 62%)`
                  : `hsl(172, 100%, 36%)`
              }
            >
              {step === signupStep.PHONE ? "Join" : "Submit"}
              {isSubscribing && (
                <LoadAnimation name="three-bounce" color="white" />
              )}
            </ButtonPrimary>
            {step === signupStep.PHONE ? (
              <Caption
                as="h2"
                css={css`
                  color: white;
                  text-align: center;
                  margin-top: 12px;
                  font-size: 12px;
                `}
              >
                By clicking, I agree to receive automated marketing messages at
                the number pvoided. Consent is not required. Msg & Data rates
                may apply. 4 msgs/mo.
                <div>Reply STOP to cancel.</div>
              </Caption>
            ) : (
              <TitleTertiary
                css={css`
                  align-self: flex-end;
                  color: white;
                  margin-top: 12px;
                  cursor: pointer;
                `}
                onClick={handleClick}
              >
                Nahh, I'm good →
              </TitleTertiary>
            )}
          </Col>
        </UserInput>
      </Layout>
    </>
  )
}

export default Form
