/**@jsx jsx */
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import Confetti from "react-dom-confetti"

import { Col, Row } from "boostly-ui2"
import { TitlePrimary, TitleSecondary } from "./Titles"
import ChatIcon from "../images/ChatIcon"
import TapIcon from "../images/TapIcon"
import RedeemButtonIcon from "../images/RedeemButtonIcon"
import ButtonPrimary from "./Buttons"
import BalloonImage from "../images/balloons.png"
import Timer from "./Timer"

const config = {
  angle: 90,
  spread: 120,
  startVelocity: 80,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}
const Container = styled.div`
  background-image: url(${require("../images/backgroundfull.png")});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: righteous;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const WelcomeText = styled.h1`
  padding: 0px;
  margin: 20px 0px 0px 0px;
  font-family: "righteous";
  font-weight: 200;
  font-size: 58px;
  text-align: center;
  letter-spacing: 2px;
  max-width: 90%;
  color: white;
`
const PrimaryText = styled(TitlePrimary)`
  font-family: sans-serif;
  font-weight: 500;
  font-size: 36px;
`
const SecondaryText = styled(TitleSecondary)`
  font-family: sans-serif;
  font-weight: 500;
  margin-top: 8px;
  font-size: 28px;
`
const Balloons = styled.img`
  height: 230px;
  width: 230px;
`
const Countdown = styled(Timer)`
  position: absolute;
  z-index: 99;
  right: 12px;
  top: 12px;
`
const Confirm = ({ onDone }) => {
  const [show, setShow] = useState(false)
  const [isClicked, setClicked] = useState(false)
  useEffect(() => {
    setTimeout(() => setShow(true), 1200)
  }, [])
  const handleTimeout = () => {
    onDone()
  }
  const handleClick = () => {
    setClicked(true)
    onDone()
  }
  return (
    <Container>
      <Countdown
        css={css`
          visibility: hidden;
        `}
        onTimeout={handleTimeout}
        duration={20}
      />
      <div
        css={css`
          width: 680px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <Col x space={"end"} width="100%">
          <Balloons src={BalloonImage} alt="Balloons" />
          <WelcomeText>Welcome to the club!</WelcomeText>
        </Col>
        <Col width="600px" mt="20px">
          <Row y space={`between`}>
            <ChatIcon
              css={css`
                height: 80px;
                width: 130px;
              `}
            />
            <Col ml="24px">
              <PrimaryText>Check your phone for a text from us.</PrimaryText>
              <SecondaryText>
                A link to your coupon will be there.
              </SecondaryText>
            </Col>
          </Row>
          <Row y mt={`12px`}>
            <TapIcon
              css={css`
                margin-left: 12px;
                height: 90px;
                width: 100px;
              `}
            />
            <Col ml="40px" space={`between`}>
              <PrimaryText>
                Tap{" "}
                <RedeemButtonIcon
                  css={css`
                    height: 50px;
                    width: 160px;
                    transform: translateY(18px);
                  `}
                />{" "}
                when you're ready.
              </PrimaryText>
              <SecondaryText>
                Show the screen to the staff before the timer ends!
              </SecondaryText>
            </Col>
          </Row>
          <ButtonPrimary
            css={css`
              align-self: center;
            `}
            h="65px;"
            w="60%"
            mt="20px"
            color={`hsl(172, 100%, 36%)`}
            onClick={handleClick}
            disabled={isClicked}
          >
            Start over
          </ButtonPrimary>
          <Confetti
            active={show}
            config={config}
            css={css`
              transform: translateX(300px);
            `}
          />
        </Col>
      </div>
    </Container>
  )
}

export default Confirm
