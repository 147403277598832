import axios from "axios"

const apiRoot = process.env.API_ROOT || "http://localhost:9000"
const baseEndpoint = `${apiRoot}/api`
const kioskEndpoint = `${baseEndpoint}/kiosks`
const subEndpoint = `${baseEndpoint}/subs`

export const load = ({ kioskId }) =>
  axios.post(`${kioskEndpoint}/load-sub-campaign`, {
    kioskId,
  })

export const subscribe = ({ campaignId, potential }) =>
  axios.post(`${subEndpoint}/new`, {
    campaignId,
    potential,
  })

export default {
  load,
  subscribe,
  getCancelToken: () => axios.CancelToken.source(),
}
