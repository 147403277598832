/**@jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import DeleteButton from "./DeleteButton"
import NumericButton from "./NumericButton"

const Container = styled.div`
  display: grid;
  grid-template: repeat(1fr, 4) / repeat(1fr, 3);
  grid-template-areas: "1 2 3" "4 5 6" "7 8 9" ". zero del";
  grid-row-gap: 14px;
  justify-items: center;
  align-items: center;
`
const columnStart = css`
  grid-column-start: 2;
`
const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
const Numpad = ({ className, onInput, mode = "PHONE", ...props }) => {
  const handleAction = value => {
    onInput(value)
  }
  return (
    <Container className={className} {...props}>
      {buttons.map(el => {
        return (
          <NumericButton
            key={el.toString()}
            css={el === 0 && columnStart}
            // onClick={() => onInput(el)}
            onTouch={() => onInput(el)}
          >
            {el}
          </NumericButton>
        )
      })}
      <DeleteButton
        // onClick={() => handleAction("x")}
        onTouch={() => handleAction("x")}
        data-testid="deleteButton"
      />
    </Container>
  )
}

export default Numpad
